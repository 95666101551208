import React from "react";
import LittleBox from './littleBox/LittleBox';
import './VarietyContainer.css';

class VarietyContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    getMeasureText(measure, value) {
        if (value < 1) {
            let measureEquivalent;
            if (measure === "KG") {
                measureEquivalent = "GR";
            } else if (measure === "LTS") {
                measureEquivalent = "ML"
            } else {
                throw new Error("Unit don't know");
            }
            return (value * 1000) + " " + measureEquivalent;
        }
        else {
            return value + " " + measure;
        }
    }

    render() {
        const variety = this.props.variety;
        return (
            <div>
                <div className="variety-title mg-b-lg">
                    <p>Medidas que se comercializan:</p>
                </div>
                <div className="variety-values">
                    {variety.values.map((v, i) => <LittleBox key={i} text={this.getMeasureText(variety.measure, v)}></LittleBox>)}
                </div>
            </div>
        );
    }
}

export default VarietyContainer;