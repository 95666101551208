import React from 'react';
import './howBusinessWork.css';
import HowBusinessWorkStep from './howBusinessWorkStep'

class HowBusinessWork extends React.Component {

    render() {
        return (
            <section className="how-business-work mg-tb-xxl">
                <HowBusinessWorkStep srcImage='./images/call.png' altImage='llamada' title='Comunicate con nosotros' subtitle='Mediante nuestros medios de contacto' />
                <HowBusinessWorkStep srcImage='./images/checklist.png' altImage='pedido' title='Hace tu pedido' subtitle='Comentanos qué necesitas' />
                <HowBusinessWorkStep srcImage='./images/time.png' altImage='tiempo' title='Esperá 72 horas hábiles' subtitle='Mientras armamos tu pedido' />
                <HowBusinessWorkStep srcImage='./images/logistics.png' altImage='camion' title='Recibí el producto' subtitle='Controla la mercadería' />
            </section>
        );
    }
}

export default HowBusinessWork;