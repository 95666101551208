import React from "react";
import Button from '../button/button';
import './ProductPresentation.css';
import VarietyContainer from '../variertyContainer/VarietyContainer';
import ReactGA from 'react-ga';
import { visitorUow } from "../../utils/visitor";

class ProductPresentation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const product = this.props.product;
        const productImageUrl = require('../images/' + product.imageName);
        return (
            <section className="product-presentation-container mg-b-xl">
                <div className="product-presentation-image">
                    <img src={productImageUrl} alt={product.name} />
                </div>
                <div className="product-presentation-description pd-xxl">
                    <div className="product-presentation-description-title mg-t-md mg-b-lg">
                        <h3>{product.name}:</h3>
                    </div>
                    <div className="product-presentation-description-description mg-tb-sm">
                        <p>{product.description}</p>
                    </div>
                    <div className="product-presentation-description-variety mg-t-lg">
                        <VarietyContainer variety={product.variety}></VarietyContainer>
                    </div>
										<a className="mg-t-lg product-presentation-description-price" target="_blank" onClick={() => ReactGA.event({category: "Contact Us", action: "PRICE", label: visitorUow()})} 
											rel="noopener noreferrer" 
											href={"https://wa.me/5401137840051?text=Hola%20vi%20el%20producto%20" + product.name + "%20y%20me%20gustaría%20saber%20el%20precio.%20Gracias!"}>
												Consultar precio
											</a>
                    <Button class="primary" buttonDescription="Volver a Galería" url="/gallery"></Button>
                </div>
            </section>
        );
    }
}

export default ProductPresentation;