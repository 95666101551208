import React from "react";
import './Footer.css'

class Footer extends React.Component {

    render() {
        return (
            <footer>
                <div className="footer-description pd-tb-lg">
                    <p>© Copyright {this.showCurrentYear()} - Desarrollado por <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/cortigeronimo/">Gerónimo Corti.</a>.</p>
                </div>
            </footer>
        );
    }

    showCurrentYear() {
        return new Date().getFullYear();
    }

}

export default Footer;