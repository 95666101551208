import React from 'react';
import ProductBox from '../productBox/ProductBox';
import './Carousel.css';
import { Link } from 'react-router-dom';

class Carousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amountMoved: 0,
            arrowRightEnabled: true
        }
        this.carouselWrapper = React.createRef();
        this.moveAllLeft = this.moveAllLeft.bind(this);
    }

    // medio villero
    handleResize = e => {
        this.setState({
            amountMoved: -1,
            arrowRightEnabled: true
        });
        this.moveAllLeft(true);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    moveAllLeft(haveToMoveLeft) {
        let arrowRightEnabled = true;
        const childProducts = this.carouselWrapper.current.childNodes;
        const distanceElements = Array.from(childProducts).reduce(
            function (prev, current) {
                return prev + current.offsetWidth + 34;
            }, 0
        );
        const containerSize = this.carouselWrapper.current.offsetWidth;
        const amountChilds = childProducts.length;
        const productSize = distanceElements / amountChilds;
        const limitSizeToMove = distanceElements - containerSize;
        if (haveToMoveLeft) {
            if (!this.state.arrowRightEnabled) {
                return;
            }
            let amountToMove = Math.min(this.state.amountMoved + 1, amountChilds);
            const teoricalDistanceToMove = amountToMove * productSize;
            const realDistanceToMove = Math.min(limitSizeToMove, teoricalDistanceToMove);
            if (limitSizeToMove < teoricalDistanceToMove) {
                arrowRightEnabled = false;
            }
            childProducts.forEach(
                p => p.style.transform = "translateX(-" + realDistanceToMove + "px)"
            );
            this.setState({
                amountMoved: amountToMove,
                arrowRightEnabled: arrowRightEnabled
            });
        } else {
            const amountToMove = Math.max(this.state.amountMoved - 1, 0);
            let distanceToMove = amountToMove * productSize;
            distanceToMove = Math.max(0, distanceToMove);
            childProducts.forEach(
                p => p.style.transform = "translateX(-" + distanceToMove + "px)"
            );
            this.setState({
                amountMoved: amountToMove,
                arrowRightEnabled: arrowRightEnabled
            });
        }
    }

    render() {
        const arrowRightImg = require('./images/chevron.png');
        const arrowLeftImg = require('./images/back.png');
        return (
            <div className="carousel-container mg-tb-xxl">
                <div className="carousel-title mg-l-lg">
                    <h3>{this.props.title} <Link to="/gallery" className="mg-rl-md">Ver todos</Link></h3>
                </div>
                <div className="carousel-wrapper" ref={this.carouselWrapper}>
                    {
                        this.props.products.map((p, i) => <ProductBox key={i} product={p}></ProductBox>)
                    }
                </div>
                <div onClick={() => this.moveAllLeft(true)} className="carousel-absolute-right-arrow pd-lg">
                    <img src={arrowRightImg} alt="arrow-right"></img>
                </div>
                <div onClick={() => this.moveAllLeft(false)} className="carousel-absolute-left-arrow pd-lg">
                    <img src={arrowLeftImg} alt="arrow-left"></img>
                </div>
            </div>
        );
    }
}

export default Carousel;