import React from 'react';
import { Link } from 'react-router-dom';
import './PageNotFound.css'

class PageNotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const logoImg = require("./images/lavoc-negro.png");
        return (
            <section className="page-not-found">
                <div class="page-not-found-logo">
                    <img src={logoImg} alt="logo-lavoc"></img>
                </div>
                <div>
                    <h1>Ups, ¡Algo inesperado pasó!</h1>
                </div>
                <div class="page-not-found-description pd-lg">
                    <h3>Te pedimos disculpas, no encontramos lo que estabas buscando. Podes volver al home presionando <Link to="/">aquí</Link>.</h3>
                </div>
                <div class="page-not-found-report-error pd-lg">
                    <h4>Si crees que esto podría ser un error, comunicate por favor mediante los medios de comunicación en este <Link to="/#contacto">link</Link>.</h4>
                </div>
            </section>
        );
    }
}

export default PageNotFound;