import React from 'react';
import "./firstView.css";
import Button from '../../button/button';

class FirstView extends React.Component {

    render() {
        return (
            <section id="first-view">
                <div className="first-view-description pd-lg">
                    <div className="first-view-description-title">
                        <h2>Productos para la construcción</h2>
                    </div>
                    <div className="first-view-description-subtitle mg-t-lg">
                        Morteros cementicios, diluyentes, pinturas, accesorios y todos los productos que necesitas para tu negocio
                    </div>
                    <div className="first-view-description-button">
                        <Button class="primary" buttonDescription="Ver Productos" url="/gallery"></Button>
                    </div>
                </div>
            </section>
        );
    }

}

export default FirstView;