import React from 'react';
import './howBusinessWorkStep.css';

class HowBusinessWorkStep extends React.Component {

    render() {
        return (
            <div className="how-business-work-step pd-rl-xl pd-tb-lg mg-tb-lg" >
                <div className="how-business-work-step-item">
                    <img src={require(this.props.srcImage + "")} alt={this.props.altImage}></img>
                </div>
                <div className="how-business-work-step-item">
                    <h3>{this.props.title}</h3>
                    <h5>{this.props.subtitle}</h5>
                </div>
            </div>
        );
    }
}

export default HowBusinessWorkStep;