import React from 'react';
import './ProductsList.css';
import ProductBox from '../productBox/ProductBox';
import { Link } from 'react-router-dom';

class ProductList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let viewAllProducts = '';
        if (this.props.viewAllProducts) {
            viewAllProducts = <Link to="/gallery" className="mg-rl-md">Ver todos</Link>;
        }
        return (
            <div>
                <div className="mg-rl-xxl favorite-products-category-title">
                    <h3>{this.props.title}
                        {viewAllProducts}
                    </h3>
                </div>
                <div className="favorite-products-category mg-tb-xl">
                    {this.props.products.map((p, i) => <ProductBox key={i} product={p} />)}
                </div>
            </div>
        );
    }
}

export default ProductList;