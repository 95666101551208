import React from 'react';
import './ProductBox.css';
import Button from '../button/button';
import { Link } from 'react-router-dom';

class ProductBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    getTruncateDescription(description) {
        const maxTextDescriptionText = 65;
        return description.length >= maxTextDescriptionText ?
            description.substring(0, maxTextDescriptionText - 1) + "..." : description;
    }

    render() {
        const productImageUrl = require('../images/' + this.props.product.imageName);
        const urlProduct = "/product/" + this.props.product.name.split(' ').join('-');
        const description = this.getTruncateDescription(this.props.product.description);
        const brand = this.props.product.brand === undefined ? undefined :
            <p className="favorite-products-product-brand mg-md"><span className="pd-rl-md pd-tb-xsm">{"Por " + this.props.product.brand}</span></p>;
        return (
            <div className="favorite-products-product mg-rl-lg mg-tb-lg pd-tb-xl">
                <Link to={urlProduct}><img className="favorite-products-product-img" src={productImageUrl} alt="removedor" /></Link>
                <p className="favorite-products-product-name mg-md pd-t-md">{this.props.product.name}</p>
                {brand}
                <p className="favorite-products-product-description mg-md">{description}</p>
                <Button buttonDescription="Ver Producto" url={urlProduct} />
            </div>
        );
    }
}

export default ProductBox;