import './LittleBox.css';
import React from 'react';

class LittleBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="little-box mg-rl-md">
                <p>{this.props.text}</p>
            </div>
        );
    }
}

export default LittleBox;