import React from 'react';
import Navigator from '../home/navigator/navigator';
import SearchEngine from './searchEngine/searchEngine';
import ProductsList from '../productsList/ProductsList';
import products from '../products';

class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.handleSearch = this.handleSearch.bind(this);
        this.state = {
            filter: undefined,
            products: products
        }
    }

    handleSearch(world) {
        this.setState({ filter: world });
    }

    render() {
        let productsToRender = this.state.products;
        if (this.state.filter !== undefined) {
            productsToRender = products.map(
                c => {
                    return {
                        ...c,
                        products: c.products
                            .filter(p => p.name.toLowerCase().includes(this.state.filter.toLowerCase()))
                            .sort((p1, p2) => p2.name - p1.name)
                    }
                }
            ).filter(c => c.products.length !== 0);
        }
        const amountProducts = productsToRender.flatMap(c => c.products).length;
        return (
            <main>
                <Navigator />
                <SearchEngine amountProducts={amountProducts} handleSearch={this.handleSearch} />
                {productsToRender.map((c, i) =>
                    <ProductsList key={i} title={c.categoryDescription} products={c.products} viewAllProducts={false}></ProductsList>)
                }
            </main>
        );
    }
}

export default Gallery;