import React from "react";
import products from '../products';
import Navigator from '../home/navigator/navigator';
import ProductPresentation from './ProductPresentation';
import Footer from '../home/footer/Footer';
import Carousel from '../carousel/Carousel';

class ProductSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const productName = this.props.match.params.productName.split('-').join(' ').toLowerCase();
        const productsObject = products.flatMap(
            c => c.products
        );
        const productObject = productsObject.find(
            p => p.name.toLowerCase().includes(productName)
        );
        const sameCategoryProducts = products.filter(c => c.products.map(p => p.name.toLowerCase()).includes(productObject.name.toLowerCase()));
        const relatedProducts = sameCategoryProducts.map(
            c => {
                return {
                    ...c,
                    "products": c.products
                        .filter(p => p.name !== productsObject.name)
                        .sort(function (a, b) { return 0.5 - Math.random() })
                        .slice(0, 8)
                }
            });
        return (
            <section>
                <Navigator></Navigator>
                <ProductPresentation product={productObject}></ProductPresentation>
                {relatedProducts.map((c, i) =>
                    <Carousel key={i} title='Algunos productos que podrían interesarte' products={c.products}></Carousel>)
                }
                <Footer></Footer>
            </section>
        );
    }
}

export default ProductSection;