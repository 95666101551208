import React from "react";
import "./navigator.css";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

class Navigator extends React.Component {
    render() {
        return (
            <nav className="navbar">
                <div className="logo-and-name">
                    <div>
                        <Link to="/">
                            <img alt="logo-lavoc" className="logo-navbar" src={require('./images/lavoc-negro.png')} />
                        </Link>
                    </div>
                    <div>
                        <Link to="/">LM LAVOC</Link>
                    </div>
                </div>
                <div className="nav-items">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/gallery">Productos</Link></li>
                        <li><HashLink to="/#contact">Contacto</HashLink></li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default Navigator;
