import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Home from "./components/home/home";
import * as serviceWorker from "./serviceWorker";
import {
    Router,
    Route,
    Switch
} from "react-router-dom"
import Gallery from "./components/gallery/gallery";
import ProductSection from "./components/product/ProductSection";
import PageNotFound from './pageNotFound/PageNotFound';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import ReactGA from 'react-ga';

const history = require("history").createBrowserHistory();

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-55549543-1');
    history.listen((location, action) => {
        ReactGA.pageview(location.pathname + location.search);
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
}

const routing = (
    <Router history={history}>
        <ScrollToTop></ScrollToTop>
        <div>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/gallery" component={Gallery} />
                <Route path="/product/:productName" component={ProductSection} />
                <Route path="*"><PageNotFound></PageNotFound></Route>
            </Switch>
        </div>
    </Router>
);

ReactDOM.render(
    routing,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
