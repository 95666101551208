import React from "react";
import "./home.css";
import Navigator from "./navigator/navigator";
import FirstView from "./firstView/firstView";
import HowBusinessWork from "./howBusinessWork/howBusinessWork";
import ContactData from "./contactData/ContactData";
import Footer from "./footer/Footer";
import products from '../products';
import Carousel from '../carousel/Carousel';

class Home extends React.Component {
    render() {
        const productsToShow = products.map(
            c => {
                return {
                    ...c,
                    "products": c.products
                        .sort(function (a, b) { return 0.5 - Math.random() })
                        .slice(0, 8)
                }
            });
        return (
            <main>
                <Navigator />
                <FirstView />
                <HowBusinessWork />
                {productsToShow.map((c, i) => {
                    const productsTitle = "Algunos productos que podrían interesarte de " + c.categoryDescription;
                    return <Carousel key={i} title={productsTitle} products={c.products}></Carousel>;
                }
                )}
                <ContactData />
                <Footer />
            </main>
        );
    }
}

export default Home;
