const products = [
    {
        "categoryDescription": "Sólidos",
        "products": [
            {
                "imageName": "tacuru.png",
                "name": "Aditivos Plásticos",
                "brand": "Weber",
                "description": "Es un aditivo que se le agrega a los morteros cementicios de revoques para mejorar su adición al ladrillo, bloques o materiales cementicios ya colocados.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        4,
                        10,
                        20
                    ]
                }
            },
            {
                "imageName": "aditivio-vinilico-multiuso-sinteplast.png",
                "name": "Aditivo Vinílico Multiuso",
                "brand": "Sinteplast",
                "description": "Aditivo líquido concentrado, usado para mejorar el rendimiento de morteros de reparación de cemento, yeso, estuco, mezclas de hormigón y capas finales. Mejorando la adherencia, plasticidad, flexibilidad y dureza.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        5,
                        20
                    ]
                }
            },
            {
                "imageName": "arcilla-plastica-blanca.png",
                "name": "Arcilla Plástica",
                "brand": "LM Lavoc",
                "description": "Carbonato utilizado, para realizar trabajos de arte, moldeo, y piezas decorativas.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.5,
                        1
                    ]
                }
            },
            {
                "imageName": "cal.png",
                "name": "Cal",
                "brand": "LM Lavoc",
                "description": "Óxido de calcio alcalino y de color blanco, obtenido a partir de la calcinación de la piedra Caliza, se usa mezclándola con agua y pigmentos para preparar pinturas o bien mezclándola con arena para preparar morteros.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        5,
                        10,
                        25
                    ]
                }
            },
            {
                "imageName": "cemento-blanco.png",
                "name": "Cemento Blanco",
                "brand": "LM Lavoc",
                "description": "Cemento portland blanco, recomendado para reparaciones pequeñas en el hogar o la industria.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        2,
                        5
                    ]
                }
            },
            {
                "imageName": "cemento-blanco-ultrarapido.png",
                "name": "Cemento Blanco Ultrarapido",
                "brand": "LM Lavoc",
                "description": "Mortero cementicio que posee la característica del cemento blanco pero con una aceleración mas rápida que este.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        2,
                        5
                    ]
                }
            },
            {
                "imageName": "cemento-gris.png",
                "brand": "LM Lavoc",
                "name": "Cemento Gris Rápido",
                "description": "Mortero cementicio que posee la característica del cemento gris pero con una aceleración mas rápida que este.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        2,
                        5
                    ]
                }
            },
            {
                "imageName": "cemento-portland.png",
                "name": "Cemento Portland",
                "brand": "LM Lavoc",
                "description": "Cemento estructural para realizar refacciones hogareñas.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        2,
                        5,
                        10,
                        25
                    ]
                }
            },
            {
                "imageName": "concreto-neomix.png",
                "name": "Concreto Neomix",
                "brand": "Neomix",
                "description": "El hormigón o concreto es un material compuesto empleado en construcción, formado esencialmente por un aglomerante al que se añade partículas o fragmentos de un agregado, agua y aditivos específicos.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        5,
                        10
                    ]
                }
            },
            {
                "imageName": "soda-caustica.png",
                "brand": "LM Lavoc",
                "name": "Destapa Cañerías",
                "description": "Producto formulado a base de soda cáustica, para la destapación de caños de material orgánico (grasas, pelos) en las cañerías de efluentes domiciliarios y/o industriales. También se usa como poderoso desengrasante de pisos. Producto tóxico y muy corrosivo.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.5,
                        1
                    ]
                }
            },
            {
                "imageName": "extracto-negro.png",
                "name": "Ferrite Negro Cemento",
                "brand": "LM Lavoc",
                "description": "Colorante puro, para teñir morteros y cales cementicios.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.5
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Ferrite Azul Cemento",
                "brand": "LM Lavoc",
                "description": "Colorante puro, para teñir morteros y cales cementicios.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.5
                    ]
                }
            },
            {
                "imageName": "extracto-ocre-amarillo.png",
                "name": "Ferrite Ocre",
                "brand": "LM Lavoc",
                "description": "Colorante puro, para teñir morteros y cales cementicios.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.5
                    ]
                }
            },
            {
                "imageName": "extracto-rojo.png",
                "name": "Ferrite Rojo N°49",
                "brand": "LM Lavoc",
                "description": "Colorante puro, para teñir morteros y cales cementicios.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.5
                    ]
                }
            },
            {
                "imageName": "extracto-verde-cemento.png",
                "name": "Ferrite Verde",
                "brand": "LM Lavoc",
                "description": "Colorante puro, para teñir morteros y cales cementicios.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.5
                    ]
                }
            },
            {
                "imageName": "ceresita.png",
                "name": "Hidrófugo",
                "brand": "Weber",
                "description": "Aditivo que se agrega a un mortero cementicio a base de portland para producir una capa aislante y repelente de la humedad.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        4,
                        10,
                        20,
                        200
                    ]
                }
            },
            {
                "imageName": "masilla-plastica.png",
                "name": "Masilla Plástica",
                "brand": "LM Lavoc",
                "description": "Fórmula a base de cauchos sintéticos y cargas; se presenta en tarugos de 1 Kg. Y se utiliza en tapas compuestas de tanques de agua, todo tipo de uniones entre chapa, plástico, mamposterías, apoyo inodoros, etc.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "masilla-viscosa.png",
                "name": "Masilla Viscosa",
                "brand": "LM Lavoc",
                "description": "Es un sellador diseñado para uso doméstico, toldos de aluminio, mesadas de cocina, filtraciones, etc. Se presenta en sachets de 500 gr.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.5
                    ]
                }
            },
            {
                "imageName": "masilla-vidrios-sanitarios-gali.png",
                "name": "Masilla para vidrios y sanitarios",
                "brand": "LM Lavoc",
                "description": "Especialmente formulada para vidrios y sanitarios.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.5,
                        1
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Masilla Para Placas De Yeso",
                "brand": "LM Lavoc",
                "description": "Para reparar placa de yeso.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1.8,
                        6,
                        17,
                        34
                    ]
                }
            },
            {
                "imageName": "mezcla-para-albañileria.png",
                "name": "Mezcla Para Albañilería Reforzada",
                "brand": "LM Lavoc",
                "description": "Mezcla para albañilería reforzada.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2,
                        5,
                        10,
                        30
                    ]
                }
            },
            {
                "imageName": "mezcla-reforazada-doble-accion-grueso-y-fino.png",
                "name": "Mezcla Reforzada Doble Acción Para Interior",
                "brand": "LM Lavoc",
                "description": "Mezcla reforzada doble acción para interior.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2,
                        5,
                        10,
                        30
                    ]
                }
            },
            {
                "imageName": "mezcla-reforazada-triple-accion.png",
                "name": "Mezcla Reforzada Triple Acción Para Exterior",
                "brand": "LM Lavoc",
                "description": "Mezcla reforzada triple acción para exterior.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2,
                        5,
                        10,
                        30
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Pastina Blanca",
                "brand": "LM Lavoc",
                "description": "Utilizada para rellenar las juntas de cerámicas y azulejos",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        5
                    ]
                }
            },
            {
                "imageName": "pastina-sinteplast.png",
                "name": "Pastina Sinteplast Beige",
                "brand": "Sinteplast",
                "description": "Mortero de alta performance para el tomado de juntas",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1.250
                    ]
                }
            },
            {
                "imageName": "pastina-sinteplast.png",
                "name": "Pastina Sinteplast Blanca",
                "brand": "Sinteplast",
                "description": "Mortero de alta performance para el tomado de juntas",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1.250
                    ]
                }
            },
            {
                "imageName": "pastina-sinteplast.png",
                "name": "Pastina Sinteplast Gris",
                "brand": "Sinteplast",
                "description": "Mortero de alta performance para el tomado de juntas",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1.250
                    ]
                }
            },
            {
                "imageName": "pastina-sinteplast.png",
                "name": "Pastina Sinteplast Habano",
                "brand": "Sinteplast",
                "description": "Mortero de alta performance para el tomado de juntas",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1.250
                    ]
                }
            },
            {
                "imageName": "pastina-sinteplast.png",
                "name": "Pastina Sinteplast Hueso",
                "brand": "Sinteplast",
                "description": "Mortero de alta performance para el tomado de juntas",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1.250
                    ]
                }
            },
            {
                "imageName": "pastina-sinteplast.png",
                "name": "Pastina Sinteplast Negra",
                "brand": "Sinteplast",
                "description": "Mortero de alta performance para el tomado de juntas",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1.250
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Sal de Limón",
                "brand": "LM Lavoc",
                "description": "La sal de limón se utiliza para blanquear maderas y mármoles percudidos.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.5,
                        1
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Arena",
                "brand": "LM Lavoc",
                "description": "La arena es un tipo de agregado fino o árido que se utiliza para fabricar hormigón, concreto y mortero.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        5,
                        10,
                        28
                    ]
                }
            },
            {
                "imageName": "acido-oxalico.png",
                "name": "Acido Oxálico",
                "brand": "LM Lavoc",
                "description": "Ideal para blanquear maderas, percudidas por la suciedad, al igual que los mármoles y/o granitos naturales con suciedad acumulada por el uso.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.5,
                        1
                    ]
                }
            },
            {
                "imageName": "pegamento.png",
                "name": "Pegamento Para Cerámicos",
                "brand": "LM Lavoc",
                "description": "Adhesivo impermeable para fijar azulejos ceramicos y baldosas sobre carpetas cementicias.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        5,
                        10
                    ]
                }
            },
            {
                "imageName": "pegamento.png",
                "name": "Pegamento Para Porcelanatos PRO Nuevo",
                "brand": "LM Lavoc",
                "description": "Adhesivo para fijar porcelanatos.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        30
                    ]
                }
            },
            {
                "imageName": "papel-crepe-gali.png",
                "name": "Papel Crepé 5cm x 25mts",
                "brand": "Gali",
                "description": "Cinta de papel de alta resistencia encrespada que se utiliza para recubrir caños de agua o de gas embutidos en mampostería protegido de corrosión y dilataciones de tuberías. Se presenta en rollo de 5 cm x 25 mts.",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Piedra Partida",
                "brand": "LM Lavoc",
                "description": "Para la construcción.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        10
                    ]
                }
            },
            {
                "imageName": "pintura-al-agua-a-la-cal.png",
                "name": "Pintura Blanca A La Cal (al Agua)",
                "brand": "LM Lavoc",
                "description": "Pintura hecha con Cal Apagada y agua y, a veces, con pigmentos, que aplicada sobre soportes pétreos con una brocha, rodillo o pulverización, endurece por contacto con el aire formando carbonato cálcico.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        4
                    ]
                }
            },
            {
                "imageName": "resina.png",
                "name": "Resina",
                "brand": "LM Lavoc",
                "description": "La resina es una secreción orgánica que producen muchas plantas, particularmente los árboles del tipo conífera. Es muy valorada por sus propiedades químicas y sus usos asociados, como por ejemplo la producción de barnices, adhesivos y aditivos alimenticios. También es un constituyente habitual de perfumes o incienso.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "fino-a-la-cal.png",
                "name": "Revoque Fino A La Cal",
                "brand": "LM Lavoc",
                "description": "Revoque fino a la cal para todo tipo de terminaciones en interiores.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        5,
                        10,
                        25
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Limpiador Para Maderas, Gel Petrilac (Sal De Limón En Gel)",
                "brand": "Petrilac",
                "description": "PETRILAC® LIMPIADOR RENOVADOR en gel restaura las maderas deterioradas por la acción de la intemperie, decoloradas ennegrecidas, y/o manchadas por salpicaduras de cal o cemento. Limpia y aclara, devuelve su color original, otorgando un aspecto natural. Su consistencia en gel facilita la aplicación en vertical con menor mano de obra. Se puede usar en muebles, aberturas, revestimientos y pisos de madera.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "sulfato-de-cobre.png",
                "name": "Sulfato De cobre",
                "brand": "LM Lavoc",
                "description": "El sulfato de cobre (II), también llamado sulfato cúprico (CuSO4), vitriolo azul, piedra azul, caparrosa azul, vitriolo romano o calcantita es un compuesto químico derivado del cobre que forma cristales azules, solubles en agua y metanol y ligeramente solubles en alcohol y glicerina. Tiene numerosas aplicaciones: como alguicida en el tratamiento de aguas, fabricación de concentrados alimenticios para animales, abonos, pesticidas, mordientes textiles, industria del cuero, pigmentos, baterías eléctricas, recubrimiento galvanizados (recubrimientos de cobre ácido por electrodeposición), sales de cobre, medicina, preservantes de la madera, procesos de grabado y litografía, reactivo para la flotación de menas que contienen zinc, industria del petróleo, caucho sintético, industria del acero, tratamiento del asfalto natural, colorante cerámico, y preparados medicinales como el agua de alibour.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.5,
                        1
                    ]
                }
            },
            {
                "imageName": "talco-industrial.png",
                "name": "Talco industrial",
                "brand": "LM Lavoc",
                "description": "Utilizado en la industria de la fibra de vidrio.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        2,
                        5
                    ]
                }
            },
            {
                "imageName": "tiza.png",
                "name": "Tiza Molida En Polvo",
                "brand": "LM Lavoc",
                "description": "Carbonato de usos múltiples. Sirve como material de carga en varios productos.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        2,
                        5
                    ]
                }
            },
            {
                "imageName": "yeso-paris.png",
                "name": "Yeso Paris",
                "brand": "LM Lavoc",
                "description": "Producto de calidad excelente utilizado para reparación en paredes y techos. Es de fácil colocación.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        2,
                        5,
                        10
                    ]
                }
            },
            {
                "imageName": "cloro-pastillas.png",
                "name": "Cloro",
                "brand": "LM Lavoc",
                "description": "Pote 5 pastillas.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1
                    ]
                }
            },
        ]
    },
    {
        "categoryDescription": "Líquidos",
        "products": [
            {
                "imageName": "aceite-de-lino-doble-cocido.png",
                "name": "Aceite De Lino Doble Cocido Puro",
                "brand": "LM Lavoc",
                "description": "Aceite desecante obtenido al exprimir las semillas de linaza. Líquido amarillo, de olor peculiar y sabor suave. Usos en barnices, pinturas, linoleo, tintas para imprenta, en papel y telas, para hacerlos resistentes al agua.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.5,
                        0.95,
                        4,
                        10,
                        200
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Glicerina Pura",
                "brand": "LM Lavoc",
                "description": "",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.1,
                        0.2,
                        0.5,
                        1,
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Hipoclorito De Sodio (CLORO PURO)",
                "brand": "LM Lavoc",
                "description": "El cloro es utilizado comúnmente para eliminar las bacterias en piscinas. También es usado en desinfectantes de uso doméstico y para blanquear la ropa. Otro de sus usos es en la fabricación de plásticos como el PVC, que es cloruro de polivinilo, el cual es empleado en la fabricación de tubos flexibles, cables, prendas de vestir, estructuras inflables y hasta en la fabricación de tejas para los techos de casas y edificios.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        1,
                        4,
                        10
                    ]
                }
            },
            {
                "imageName": "acido.png",
                "name": "Ácido Muriático",
                "brand": "LM Lavoc",
                "description": "Ácido de excelente calidad, preparado para quitar restos de albañilería y desincrustaciones calcáreas, también lo usan los profesionales del gremio de pileteros. Producto cáustico y corrosivo, no dejar al alcance de los niños.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.95,
                        4,
                        10
                    ]
                }
            },
            {
                "imageName": "aguarras.png",
                "name": "Aguarras Mineral Puro",
                "brand": "LM Lavoc",
                "description": "Excelente diluyente para esmaltes sintéticos y barnices, al ser un producto puro, resalta el brillo y adhesión de la pintura sobre la superficies, con bajo olor y secado rápido, es apto para cualquier profesional o amateurs.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.5,
                        0.95,
                        4,
                        10
                    ]
                }
            },
            {
                "imageName": "acelerante.png",
                "name": "Acelerante",
                "brand": "LM Lavoc",
                "description": "Inorgánico. Acelerador de fragüe para cementos en climas fríos.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        1,
                        5
                    ]
                }
            },
            {
                "imageName": "adhesivos-para-tubos-y-accesorios-de-pvc.png",
                "name": "Adhesivo Para Tubos Y Accesorios",
                "brand": "Gali",
                "description": "Ideal para mantener tus tuberías y conexiones fijas y sin pérdidas.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.06,
                        0.1,
                        0.25,
                        0.5,
                        1
                    ]
                }
            },
            {
                "imageName": "Agua-desmineralizada.png",
                "name": "Agua Desmineralizada",
                "brand": "LM Lavoc",
                "description": "Compuesto químico representado por la formula H2O. Desionizada bajo normas ISO 9001.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.95,
                        5,
                        10
                    ]
                }
            },
            {
                "imageName": "aguarras-vegetal.png",
                "name": "Aguarras Vegetal",
                "brand": "LM Lavoc",
                "description": "Es un líquido volátil e incoloro obtenido por destilación de resinas provenientes de variadas especies de pino. La usada en colores para artistas, cuando se evapora, no debe dejar ningún residuo graso. Tiene un olor característico que puede resultar poco tolerable para personas sensibles a este tipo de solventes.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.25,
                        0.5,
                        0.95
                    ]
                }
            },
            {
                "imageName": "alcohol-de-lustre.png",
                "name": "Alcohol De Lustre",
                "brand": "LM Lavoc",
                "description": "Compuesto químico, a base de alcohol etílico. Especial para diluir la goma laca en escamas.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.5,
                        0.95,
                        5
                    ]
                }
            },
            {
                "imageName": "diluyente-alcohol-desnaturalizado.png",
                "name": "Alcohol De Quemar Desnaturalizado",
                "description": "Diluyente para limpieza de grasitud en superficies inorgánicas como por ejemplo en vidrios. También utilizado como alcohol para quemar.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "desoxidante.png",
                "name": "Desoxidante Y Fosfatizante",
                "brand": "LM Lavoc",
                "description": "Compuesto químico a base de ácido fosfórico. Ideal a eliminar el óxido superficial en hierro y acero.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.95
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Desoxidante Y Fosfatizante",
                "brand": "Sinteplast",
                "description": "Compuesto químico a base de ácido fosfórico. Ideal a eliminar el óxido superficial en hierro y acero.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.5,
                        1
                    ]
                }
            },
            {
                "imageName": "come-grasa.png",
                "name": "Destapa Cañería Líquido",
                "brand": "LM Lavoc",
                "description": "Compuesto químico a base de hidróxido de sodio líquido. Preparado para limpieza de cañerías con materia orgánica.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.45,
                        0.95
                    ]
                }
            },
            {
                "imageName": "diluyente-para-cemento-de-contacto.png",
                "name": "Diluyente Para Cementos De Contacto",
                "brand": "LM Lavoc",
                "description": "Este diluyente fue desarrollado para adhesivos, del tipo compuesto por una resina fenólica reactiva, combinada con un elastómero tipo cloropreno como componentes principales y aditivos que generan la vulcanización en frío del adhesivo, tornándolo así insoluble a las pocas horas de aplicado y evaporado el disolvente. El alto poder solvente de este diluyente, también mejora el rendimiento de los adhesivos, por la completa disolución de los distintos componentes, asegurando su íntimo contacto para una reacción completa, asegurando el desarrollo total del poder adhesivo que mantiene unidos a los adherendos y el máximo rendimiento. Al colaborar eficazmente al rápido desarrollo de la fuerza adhesiva de los productos, aumenta la productividad de los procesos industriales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.25,
                        0.5,
                        1
                    ]
                }
            },
            {
                "imageName": "kerosene.png",
                "name": "Kerosene",
                "brand": "LM Lavoc",
                "description": "Producto para limpieza y/o encendido.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.95,
                        5,
                        10
                    ]
                }
            },
            {
                "imageName": "removedor.png",
                "name": "Removedor Líquido",
                "brand": "LM Lavoc",
                "description": "Ideal para remover pinturas viejas, en hierros y maderas. Por ser orgánico, no daña la madera, al contrario de los removedores en gel, inorgánicos.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.5,
                        0.95,
                        4,
                        10
                    ]
                }
            },
            {
                "imageName": "removedor-gel-aquiles.png",
                "name": "Removedor gel",
                "brand": "Aquiles",
                "description": "Ideal para remover pinturas viejas, en hierros y maderas. Por ser orgánico, no daña la madera, al contrario de los removedores en gel, inorgánicos.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.5,
                        1,
                        4
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Removedor gel Petrilac",
                "brand": "Petrilac",
                "description": "Ideal para remover pinturas viejas, en hierros y maderas. Por ser orgánico, no daña la madera, al contrario de los removedores en gel, inorgánicos.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        1,
                        4
                    ]
                }
            },
            {
                "imageName": "solvente.png",
                "name": "Solvente Mineral Industrial",
                "brand": "LM Lavoc",
                "description": "Diluyente de pinturas y barnices, limpieza general de instalaciones de Maquinaria, remover manchas en el piso, diluir pinturas de aceite, limpiar brochas, rodillos y otros implementos utilizados al pintar con pinturas de aceite, en la industria textil, como solvente para secantes, desengrasantes de metales, en la preparación de pulidores o betún para muebles, zapatos, solvente de limpieza en seco.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.5,
                        0.95,
                        4,
                        10
                    ]
                }
            },
            {
                "imageName": "thinner-standard.png",
                "name": "Thinner Standard",
                "brand": "LM Lavoc",
                "description": "Este thinner, es de limpieza de los utensilios utilizados en el pintado.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.95,
                        4
                    ]
                }
            },
            {
                "imageName": "thinner-oro.png",
                "name": "Thinner Super Oro",
                "brand": "LM Lavoc",
                "description": "Thinner de calidad superior apto para pinturas tricapas.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.95,
                        4,
                        10
                    ]
                }
            },
            {
                "imageName": "thinner-super.png",
                "name": "Thinner Super Plata",
                "brand": "LM Lavoc",
                "description": "Thinner universal para usos multiples. Sistema bicapa y duco.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.5,
                        0.95,
                        4,
                        10
                    ]
                }
            }
        ]
    },
    {
        "categoryDescription": "Accesorios",
        "products": [
            {
                "imageName": "borax-en-polvo.png",
                "name": "Borax En Polvo",
                "brand": "LM Lavoc",
                "description": "El bórax se utiliza ampliamente en detergentes, suavizantes, jabones, desinfectantes y pesticidas. Se utiliza en la fabricación de esmaltes, cristal y cerámica. También se convierte fácilmente en ácido bórico o en borato, que tienen muchos usos. Una mezcla de cloruro de bórax y amonio se utiliza como fundente (flux) al soldar hierro y acero. Su función es bajar el punto de fusión del indeseado óxido de hierro. 45 1 Cal 46 1 Concreto Neomix 47 2 Diluyente para cementos de contacto El bórax también se utiliza mezclado con agua como fundente al soldar oro, plata, etc. en joyería. Permite que el metal fundido fluya uniformemente sobre el molde.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.25,
                        0.5
                    ]
                }
            },
           {
                    "imageName": "no-disponible.png",
                    "name": "Rollo Tela Esmeril 3,5 cm x 2 mts",
                    "brand": "LM Lavoc",
                    "description": "Producto abrasivo (Tela Esmeril) para limpieza de caños de PVC, cobre, bronce, etc. Para su posterior pegado o soldado. Se presenta en rollo de 3,5 cm x 2 mts.",
                    "variety": {
                        "measure": "UDS",
                        "values": [
                            1
                        ]
                    }
           },
            {
                "imageName": "cinta-teflon-gali.png",
                "name": "Cintas de Teflón de 1/2 pulgada",
                "brand": "LM Lavoc",
                "description": "Para sellado de Roscas.",
                "variety": {
                    "measure": "MTS",
                    "values": [
                        10,
                        20
                    ]
                }
            },
            {
                "imageName": "cinta-teflon-alta-densidad-gali.png",
                "name": "Cintas de Teflón Alta Densidad de 1/2 pulgada",
                "brand": "LM Lavoc",
                "description": "Para sellado de Roscas.",
                "variety": {
                    "measure": "MTS",
                    "values": [
                        10
                    ]
                }
            },
            {
                "imageName": "cinta-teflon-gali.png",
                "name": "Cintas de Teflón de 3/4 pulgada",
                "brand": "LM Lavoc",
                "description": "Para sellado de Roscas.",
                "variety": {
                    "measure": "MTS",
                    "values": [
                        10,
                        20
                    ]
                }
            },
            {
                "imageName": "cinta-teflon-alta-densidad-gali.png",
                "name": "Cintas de Teflón Alta Densidad de 3/4 pulgada",
                "brand": "LM Lavoc",
                "description": "Para sellado de Roscas.",
                "variety": {
                    "measure": "MTS",
                    "values": [
                        10,
                        40
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Papel embreado (Rollo)",
                "brand": "LM Lavoc",
                "description": "Se usa principalmente como aislante y barrera contra la humedad.",
                "variety": {
                    "measure": "MTS",
                    "values": [
                        20
                    ]
                }
            },
            {
                "imageName": "cinta-teflon-gali.png",
                "name": "Cintas de Teflón de 3/4 pulgadas",
                "brand": "LM Lavoc",
                "description": "Para sellado de Roscas.",
                "variety": {
                    "measure": "MTS",
                    "values": [
                        10,
                        20
                    ]
                }
            },
            {
                "imageName": "fino.png",
                "name": "Cañamo Peinado Fino",
                "brand": "Gali",
                "description": "Fibra vegetal de peinado fino que se utiliza en uniones roscadas de caños y accesorios de agua, gas y vapor. Especialmente recomendado en uniones de alta precisión de trabajo, se complementa con selladores o grasas.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.02,
                        0.05,
                        0.1,
                        0.5
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Cinta Embalaje Marrón Sinteplast",
                "brand": "Sinteplast",
                "description": "Cinta para embalar color marrón de 48x50 mts",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Cinta Embalaje Transparente Sinteplast",
                "brand": "Sinteplast",
                "description": "Cinta para embalar color marrón de 48x50 mts",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "cinta-enmascarar-azul.png",
                "name": "Cinta Enmascarar Azul Sinteplast 12/50",
                "brand": "Sinteplast",
                "description": "Cinta para enmascarar color azul de 12/50 mts",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "cinta-enmascarar-azul.png",
                "name": "Cinta Enmascarar Azul Sinteplast 18/50",
                "brand": "Sinteplast",
                "description": "Cinta para enmascarar color azul de 18/50 mts",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "cinta-enmascarar-azul.png",
                "name": "Cinta Enmascarar Azul Sinteplast 24/50",
                "brand": "Sinteplast",
                "description": "Cinta para enmascarar color azul de 24/50 mts",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "cinta-enmascarar-azul.png",
                "name": "Cinta Enmascarar Azul Sinteplast 36/50",
                "brand": "Sinteplast",
                "description": "Cinta para enmascarar color azul de 36/50 mts",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "cinta-enmascarar-azul.png",
                "name": "Cinta Enmascarar Azul Sinteplast 48/50",
                "brand": "Sinteplast",
                "description": "Cinta para enmascarar color azul de 48/50 mts",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "cinta-enmascarar-papel.png",
                "name": "Cinta Enmascarar Papel Sinteplast 12/50",
                "brand": "Sinteplast",
                "description": "Cinta para enmascarar papel de 12/50 mts",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "cinta-enmascarar-papel.png",
                "name": "Cinta Enmascarar Papel Sinteplast 18/50",
                "brand": "Sinteplast",
                "description": "Cinta para enmascarar papel de 18/50 mts",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "cinta-enmascarar-papel.png",
                "name": "Cinta Enmascarar Papel Sinteplast 24/50",
                "brand": "Sinteplast",
                "description": "Cinta para enmascarar papel de 24/50 mts",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "cinta-enmascarar-papel.png",
                "name": "Cinta Enmascarar Papel Sinteplast 36/50",
                "brand": "Sinteplast",
                "description": "Cinta para enmascarar papel de 36/50 mts",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Enduido Plástico Interior/Exterior",
                "brand": "Gali",
                "description": "Utilizado para reparaciones de paredes.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        1,
                        4,
                        10,
                        20
                    ]
                }
            },
            {
                "imageName": "decapante.png",
                "name": "Decapante Líquido",
                "brand": "Gali",
                "description": "Producto especialmente formulado para decapar caños y accesorios de hidro, bronce, latón, etc. Dejándolos en óptimas condiciones para estañar.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.07,
                        0.2,
                        0.5
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Soga Calafateo",
                "brand": "LM LAVOC",
                "description": "Hilo de algodón para calafatear ranuras finas.",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "estopa-de-lustre.png",
                "name": "Estopa De Lustre",
                "brand": "GH",
                "description": "Estopa proveniente de Hilos de Algodón Blanco, suaves y de gran absorción especialmente en aceites, solventes y agua, Se presenta peinada, ideal para lustre y acabados utilizadas en la industria gráfica, estaciones de servicio, laboratorios, metalúrgicas, pinturerías, talleres de reparación de automóviles.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.3
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Estopa Blanca Limpieza",
                "brand": "GH",
                "description": "Son de colores, pueden rallar.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.3,
                        5
                    ]
                }
            },
            {
                "imageName": "grafito-en-polvo.png",
                "name": "Grafito En Polvo",
                "brand": "LM Lavoc",
                "description": "Lubricante sólido principalmente para cerraduras.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.015
                    ]
                }
            },
            {
                "imageName": "fundente-para-plata.png",
                "name": "Fundente Para Soldadura De Bronce y Plata",
                "brand": "Gali",
                "description": "Fundente Para Soldadura De Bronce y Plata.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.1
                    ]
                }
            },
            {
                "imageName": "grasa-amarilla.png",
                "name": "Grasa Amarilla Multiuso",
                "brand": "Gali",
                "description": "Grasa de origen mineral llamada multiuso, formulada para ser utilizada en desplazamientos verticales y horizontales, en aquellos movimientos que no deben soportar carga. Utilizada en el hogar y la industria, donde no hay una gran exigencia.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.125,
                        0.25,
                        0.5,
                        1
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Grasa Roja Multiuso",
                "brand": "Gali",
                "description": "Grasa roja multiuso de gran estabilidad y alto poder lubricante. Ideal para lubricar rodamientos en condiciones severas de temperatura, ideal para lubricar cadenas, engranajes, crucetas, puntas, etc. Resiste ambientes húmedos y no se lava con el agua.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.125,
                        0.25,
                        0.5,
                        1
                    ]
                }
            },
            {
                "imageName": "ladrillo-comun.png",
                "name": "Ladrillo común",
                "brand": "LM LAVOC",
                "description": "Ladrillo común.",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "ladrillo-hueco-8x18x33.png",
                "name": "Ladrillo Hueco 8x18x33",
                "brand": "LM LAVOC",
                "description": "Ladrillo de 6 agujeros 8x18x33",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Ladrillo hueco",
                "brand": "LM LAVOC",
                "description": "Ladrillo hueco cerramiento 8cm x 18cm x 33cm.",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "grasa-siliconada-gali.png",
                "name": "Grasa Siliconada de Pomo",
                "brand": "Gali",
                "description": "Especialmente formulada para lubricar o´ring de gritería sanitaria y armado de diafragmas de calefones. Protege contra la oxidación y tiene alto poder de lubricante.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.05,
                        0.25,
                        0.5,
                        1
                    ]
                }
            },
            {
                "imageName": "grasa-grafitada.png",
                "name": "Grasa Grafitada",
                "brand": "Gali",
                "description": "Especialmente formulada para el sellado de las llaves de paso y robinetes de gas. Por su contenido de grafito tiene una gran resistencia a la temperatura y el agua, protege contra la corrosión, disipa el calor y repele el agua.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.04,
                        0.1,
                        0.25,
                        0.5,
                        1
                    ]
                }
            },
            {
                "imageName": "Litargirio.png",
                "name": "Litargirio En Polvo",
                "brand": "Gali",
                "description": "Combinados forman una pasta que se utiliza en el sellado de las roscas de accesorios y caños epoxy para gas.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.1,
                        0.2,
                        0.5,
                        1
                    ]
                }
            },
            {
                "imageName": "paño-para-soldar.png",
                "name": "Paño Para Soldar",
                "brand": "Gali",
                "description": "Trapo impregnado con ácido graso que se utiliza en soldaduras de plomo.",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "parafina.png",
                "name": "Parafina En Plancha",
                "brand": "LM Lavoc",
                "description": "Sustancia compuesta por hidrocarburos, sólida, opalina, inodora, menos densa que el agua y fácilmente fusible, que se obtiene normalmente como subproducto de la fabricación de aceites lubrificantes derivados del petróleo y tiene múltiples aplicaciones industriales y farmacéuticas: vela, aislante, envase de parafina. El peso es aproximado.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        5
                    ]
                }
            },
            {
                "imageName": "pintura-asfaltica.png",
                "name": "Pintura Asfáltica",
                "brand": "Gali",
                "description": "Pintura derivada del petróleo, que se utiliza como impermeabilizante de techos, mampostería y caños.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        4,
                        18
                    ]
                }
            },
            {
                "imageName": "pintura-epoxi.png",
                "name": "Pintura Epoxi Para Caños Amarrillo",
                "brand": "Gali",
                "description": "Producto especialmente formulado para recubrimiento y reparación de caños y accesorios de gas. Previene el avance de la corrosión. Se presenta en dos componentes (A y B), que se mezclan en partes iguales. Y se aplica a pincel.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.08,
                        0.25,
                        0.5,
                        1
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Sustituto de amianto",
                "brand": "LM LAVOC",
                "description": "Viene en planchas de 0,85cm X 0,58cm X 2,5mm.",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        2
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Bolsas para escombros",
                "brand": "LM LAVOC",
                "description": "Bolsas Para Escombros super resistentes 48x70cm.",
                "variety": {
                    "measure": "UDS",
                    "values": [
                        10
                    ]
                }
            },
            {
                "imageName": "sellador-de-roscas.png",
                "name": "Sellador De Roscas",
                "brand": "Gali",
                "description": "Ideal para sellar roscas de caños de agua.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.03,
                        0.07,
                        0.22
                    ]
                }
            },
            {
                "imageName": "sellador-polipropileno-verde.png",
                "name": "Sellador Para Caño Polipropileno (VERDE)",
                "brand": "Gali",
                "description": "Formulado especialmente para uniones roscadas de caños y accesorios de polipropileno que necesitan un sellado riguroso. Apto para la conducción de agua caliente y fría, protege las roscas y admite vibraciones y permite desarmar sin dejar que se rompan las piezas. Su color es verde.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.025,
                        0.065,
                        0.125
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Fijador Sellador",
                "brand": "LM LAVOC",
                "description": "Fijador-Sellador líquido.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        1,
                        4,
                        10,
                        20
                    ]
                }
            },
            {
                "imageName": "trapos-de-limpieza.png",
                "name": "Trapos De Limpieza",
                "brand": "LC Corrales",
                "description": "Utilizado para limpiar superficies.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.3
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Viruta De Acero Fina",
                "brand": "Splendor",
                "description": "Utilizada especialmente para quitar barnices de muebles, ventanas y pisos de madera. Limpieza de superficies, rejas, portones, muebles de jardín. Limpieza de restos de pintura en casi todas las superficies y es habitual su utilización luego de los removedores químicos. Sarro en lozas o mesadas, de las parrillas remueve rápida y eficazmente los restos grasos u óxidos.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.25
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Viruta De Acero Mediana",
                "brand": "Splendor",
                "description": "Utilizada especialmente para quitar barnices de muebles, ventanas y pisos de madera. Limpieza de superficies, rejas, portones, muebles de jardín. Limpieza de restos de pintura en casi todas las superficies y es habitual su utilización luego de los removedores químicos. Sarro en lozas o mesadas, de las parrillas remueve rápida y eficazmente los restos grasos u óxidos.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.25
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Viruta De Acero Gruesa",
                "brand": "Splendor",
                "description": "Utilizada especialmente para quitar barnices de muebles, ventanas y pisos de madera. Limpieza de superficies, rejas, portones, muebles de jardín. Limpieza de restos de pintura en casi todas las superficies y es habitual su utilización luego de los removedores químicos. Sarro en lozas o mesadas, de las parrillas remueve rápida y eficazmente los restos grasos u óxidos.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        0.25
                    ]
                }
            },
            {
                "imageName": "aerosol-lubricante-siliconado-gali.png",
                "name": "Aceite Lubricante Siliconado",
                "brand": "Gali",
                "description": "Fabricado a base de silicona, para uso en goma y plástico. Diseñado para facilitar y aligerar la aplicación de aros de goma, también de las tuberías con juntas elásticas. Dando como resultado un trabajo más limpio y de mejor terminación. Se presenta en lata de 440 cc.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.44
                    ]
                }
            },
        ]
    },
    {
        "categoryDescription": "Weber",
        "products": [
            {
                "imageName": "yeso-adhesivo-interiores.png",
                "name": "Mezcla Para Reparaciones (Interiores)",
                "brand": "Placomix",
                "description": "Placomix® INTERIORES es una mezcla para reparaciones a base de yeso, que sirve para arreglar grietas e imperfecciones en el interior del hogar de la forma más fácil y rápida.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        3.5
                    ]
                }
            },
            {
                "imageName": "yeso-adhesivo-exteriores.png",
                "name": "Mezcla Para Reparaciones (Exteriores)",
                "brand": "Placomix",
                "description": "Placomix® Exteriores es una mezcla para reparaciones a base de cemento gris, que sirve para arreglar grietas, agujeros y huecos en paredes y techos del exterior del hogar de la forma más fácil y rápida.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        3.5
                    ]
                }
            },
            {
                "imageName": "yeso-obra-tuyango.png",
                "name": "Yeso de obra",
                "brand": "Tuyango",
                "description": "Yeso tradicional para REPARACIONES en paredes y molduras. Producto apto para ser utilizado sobre diferentes superficies de interiores como revoques, engrosados de yeso-cal-arena o enlucidos.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        3.5,
                        30
                    ]
                }
            },
            {
                "imageName": "acelerante-frague-weber.png",
                "name": "Acelerante De Frague Con Cloruro",
                "brand": "Weber",
                "description": "Aditivo líquido con cloruro ideal como acelerante de fragüe y anticongelante.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        1,
                        5
                    ]
                }
            },
            {
                "imageName": "mezcla-multiuso-weber.png",
                "name": "Mezcla Multiuso Weber Reforzada",
                "brand": "Weber",
                "description": "Mortero ideal para utilizar como mezcla de asiento para ladrillos cerámicos, comunes, reparar carpetas y revoques.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        30
                    ]
                }
            },
            {
                "imageName": "espuma-poliuretanica-weber.png",
                "name": "Espuma Poliuretánica",
                "brand": "Weber",
                "description": "Espuma poliuretánica de fácil aplicación, fácil manipulación y gran rendimiento. Para Pared Exterior, Fachada, Pared Interna, Pared pintada, Techo, Techo de chapas.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.3,
                        0.5,
                        0.75
                    ]
                }
            },
            {
                "imageName": "mezcla-de-asiento.png",
                "name": "Mezcla De Asiento",
                "brand": "Weber",
                "description": "Mortero de asiento listo para usar. Para ladrillos cerámicos, bloques de hormigón y ladrillos de hormigón celular.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        3
                    ]
                }
            },
            {
                "imageName": "limpiador-ceramicos-weber.png",
                "name": "Limpiador para cerámicos",
                "brand": "Weber",
                "description": "Limpiador líquido de restos de obra ( mezcla adhesiva, pastina, cal, yeso, etc), hongos, sarro y y demás manchas de similar intensidad sobre piezas cerámicas y juntas.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        1
                    ]
                }
            },
            {
                "imageName": "tec-glass-weber.png",
                "name": "Mezcla para ladrillos de vidrio",
                "brand": "Weber",
                "description": "Mezcla para colocar ladrillos de vidrio.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        5,
                        25
                    ]
                }
            },
            {
                "imageName": "pasta-weber.png",
                "name": "Pasta",
                "brand": "Weber",
                "description": "Mezcla adhesiva blanca lista para usar.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        7,
                        35
                    ]
                }
            },
            {
                "imageName": "mortero-refractario-weber.png",
                "name": "Mortero Refractario",
                "brand": "Weber",
                "description": "Mortero para tejuelas y ladrillos refractarios.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        5,
                        20
                    ]
                }
            },
            {
                "imageName": "pastina-weber-classic.png",
                "name": "Pastina Weber Arena",
                "brand": "Weber",
                "description": "Pastina cementicia impermeable para tomar juntas cerámicas de terminación fina.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2
                    ]
                }
            },
            {
                "imageName": "pastina-weber-classic.png",
                "name": "Pastina Weber Beige",
                "brand": "Weber",
                "description": "Pastina cementicia impermeable para tomar juntas cerámicas de terminación fina.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2
                    ]
                }
            },
            {
								"imageName": "pastina-weber-classic.png",
                "name": "Pastina Weber Champagne",
                "brand": "Weber",
                "description": "Pastina cementicia impermeable para tomar juntas cerámicas de terminación fina.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2
                    ]
                }
            },
            {
								"imageName": "pastina-weber-classic.png",
                "name": "Pastina Weber Ferrico",
                "brand": "Weber",
                "description": "Pastina cementicia impermeable para tomar juntas cerámicas de terminación fina.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2
                    ]
                }
            },
            {
								"imageName": "pastina-weber-classic.png",
                "name": "Pastina Weber Habano",
                "brand": "Weber",
                "description": "Pastina cementicia impermeable para tomar juntas cerámicas de terminación fina.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2
                    ]
                }
            },
            {
								"imageName": "pastina-weber-classic.png",
                "name": "Pastina Weber Hueso",
                "brand": "Weber",
                "description": "Pastina cementicia impermeable para tomar juntas cerámicas de terminación fina.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2
                    ]
                }
            },
            {
								"imageName": "pastina-weber-classic.png",
                "name": "Pastina Weber Medano",
                "brand": "Weber",
                "description": "Pastina cementicia impermeable para tomar juntas cerámicas de terminación fina.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2
                    ]
                }
            },
            {
								"imageName": "pastina-weber-classic.png",
                "name": "Pastina Weber Negro",
                "brand": "Weber",
                "description": "Pastina cementicia impermeable para tomar juntas cerámicas de terminación fina.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2
                    ]
                }
            },
            {
								"imageName": "pastina-weber-classic.png",
                "name": "Pastina Weber Niebla",
                "brand": "Weber",
                "description": "Pastina cementicia impermeable para tomar juntas cerámicas de terminación fina.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2
                    ]
                }
            },
            {
								"imageName": "pastina-weber-classic.png",
                "name": "Pastina Weber Nieve",
                "brand": "Weber",
                "description": "Pastina cementicia impermeable para tomar juntas cerámicas de terminación fina.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2,
                        5,
                        25
                    ]
                }
            },
            {
								"imageName": "pastina-weber-classic.png",
                "name": "Pastina Weber Perla",
                "brand": "Weber",
                "description": "Pastina cementicia impermeable para tomar juntas cerámicas de terminación fina.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2
                    ]
                }
            },
            {
								"imageName": "pastina-weber-classic.png",
                "name": "Pastina Weber Perlato",
                "brand": "Weber",
                "description": "Pastina cementicia impermeable para tomar juntas cerámicas de terminación fina.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2
                    ]
                }
            },
            {
								"imageName": "pastina-weber-classic.png",
                "name": "Pastina Weber Plata",
                "brand": "Weber",
                "description": "Pastina cementicia impermeable para tomar juntas cerámicas de terminación fina.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2
                    ]
                }
            },
            {
								"imageName": "pastina-weber-classic.png",
                "name": "Pastina Weber Plomo",
                "brand": "Weber",
                "description": "Pastina cementicia impermeable para tomar juntas cerámicas de terminación fina.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2
                    ]
                }
            },
            {
								"imageName": "pastina-weber-classic.png",
                "name": "Pastina Weber Terracota",
                "brand": "Weber",
                "description": "Pastina cementicia impermeable para tomar juntas cerámicas de terminación fina.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        2
                    ]
                }
            },
            {
                "imageName": "no-disponible.png",
                "name": "Pegamento Weber",
                "brand": "Weber",
                "description": "Pegamento impermeable con ceresita ideal para colocar cerámicos en pisos y paredes interiores.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        5,
                        10,
                        30
                    ]
                }
            },
            {
                "imageName": "pegamento-ceramicos-gris-weber.png",
                "name": "Pegamento Para Cerámicos Weber Gris",
                "brand": "Weber",
                "description": "Pegamento impermeable para colocar cerámicos en pisos y paredes interiores.",
                "variety": {
                    "measure": "KG",
                    "values": [
                        30
                    ]
                }
            },
        ]
    },
    {
        "categoryDescription": "Aerosoles",
        "products": [
            {
                "imageName": "aerosol-sinteplast-alta-temperatura.png",
                "name": "Aerosol Alta Temperatura Aluminio",
                "brand": "Sinteplast",
                "description": "Esmalte resistente al calor. Producto indicado como protección de piezas que estén sometidas a temperaturas de hasta 500°C.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
                "imageName": "aerosol-sinteplast-alta-temperatura.png",
                "name": "Aerosol Alta Temperatura Negro",
                "brand": "Sinteplast",
                "description": "Esmalte resistente al calor. Producto indicado como protección de piezas que estén sometidas a temperaturas de hasta 500°C.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Azul Marino",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Barniz Brillante",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Barniz Satinado",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Bermellon",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Blanco",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Blanco Mate",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Blanco Satinado",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Convertidor Rojo",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Fucsia",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Gris",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Gris Espacial",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Gris Metálico Medio",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Hueso",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Magenta Fluo",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Mango Shot",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Marron Africano",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Naranja",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Negro",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Negro Mate",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Negro Satinado",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Plata",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
							 "imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Rosa",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Verde Claro",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
								"imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Verde Inglés",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
            {
                "imageName": "aerosol-sinteplast-3-en-1.png",
                "name": "Aerosol 3 en 1 Violeta",
                "brand": "Sinteplast",
                "description": "Esmalte de terminación alquídico de rápido secado para uso interior y exterior con buena retención del color y buena resistencia a la intemperie, mayor que la de los esquemas alquídicos convencionales.",
                "variety": {
                    "measure": "LTS",
                    "values": [
                        0.24
                    ]
                }
            },
        ]
    }
];

export default products;