import React from 'react';
import './button.css';
import { Link } from 'react-router-dom';

class Button extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const isPrimaryColor = this.props.class === undefined || this.props.class === 'primary';
        const finalClass = isPrimaryColor ? 'button-lavoc-primary' : 'button-lavoc-secondary';
        return (
            <Link className={finalClass + " pd-md mg-tb-xl"} to={this.props.url}>
                {this.props.buttonDescription}
            </Link>

        );
    }
}

export default Button;