import React from 'react';
import './ContactData.css';
import ReactGA from 'react-ga';

class ContactData extends React.Component {

    render() {
        const phoneImageUrl = require("./images/phone.png");
        const whatsappImageUrl = require("./images/whatsapp.png");
        const mailImageUrl = require("./images/mail.png");
        return (
            <section id="contact" className="contact-data-container pd-xxl">
                <div className="contact-data">
                    <div className="contact-data-info">
                        <div className="contact-data-item">
                            <div className="contact-data-item-left">
                                <img src={phoneImageUrl} alt="phone"></img>
                            </div>
                            <div className="contact-data-item-right">
                                <h2>Telefonos:</h2>
                                <ul>
                                    <li>+54 (011) 4730-0959</li>
                                    <li>+54 (011) 5648-7509</li>
                                    <li>+54 (011) 5648-7510</li>
                                </ul>
                            </div>
                        </div>
                        <div className="contact-data-item">
                            <div className="contact-data-item-left">
                                <img src={whatsappImageUrl} alt="whatsapp"></img>
                            </div>
                            <div className="contact-data-item-right">
                                <h2>WhatsApp:</h2>
                                <ul>
                                    <li><a target="_blank" onClick={() => ReactGA.event({category: "Contact Us", action: "WSP", label: "prueba"})} rel="noopener noreferrer" href="https://wa.me/5401137840051?text=Hola%20vi%20la%20página%20web%20y%20me%20gustaría%20saber%20más">+54 11-3784-0051</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="contact-data-item">
                            <div className="contact-data-item-left">
                                <img src={mailImageUrl} alt="mail"></img>
                            </div>
                            <div className="contact-data-item-right">
                                <h2>Mails:</h2>
                                <ul>
                                    <li>lavocsrl@gmail.com</li>
                                    <li>lmlavoc@hotmail.com</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="contact-data-additional-info pd-xl">
                        <div className="contact-data-item-addition-item">
                            <div>
                                <h2>Dirección:</h2>
                                <p>Benjamín Franklin 1364, C1602 Florida Oeste, Provincia de Buenos Aires.</p>
                            </div>
                        </div>
                        <div className="contact-data-item-addition-item">
                            <div>
                                <h2>Horario de Atención:</h2>
                                <p>Lunes a Viernes, de 09:00hs a 13:00hs y de 14:00hs a 18:00hs</p>
                            </div>
                        </div>
                        <div className="contact-data-item-addition-item">
                            <div>
                                <h2>Envíos a otras provincias:</h2>
                                <p>Se envían productos a todo el país, a través de expreso, a cargo y responsabilidad del cliente.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-data-map pd-lg">
                    <iframe title="ubication-lavoc" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3286.4421261417633!2d-58.5208512842227!3d-34.54235948047546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb72e7355bd01%3A0xbc5ebe6fb32d07ef!2sLm%20Lavoc!5e0!3m2!1ses-419!2sar!4v1596315645988!5m2!1ses-419!2sar" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                </div>
            </section>
        );
    }

}

export default ContactData;