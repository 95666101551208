import React from 'react';
import './searchEngine.css';

class SearchEngine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.buttonReference = React.createRef();
        this.handleEnter = this.handleEnter.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.buttonReference.current.focus();
    }

    render() {
        const searchImage = require("./images/search.png");
        return (
            <div className="search-engine-container pd-xxl">
                <div className="search-engine">
                    <input ref={this.buttonReference} onKeyUp={this.handleEnter} type="text" placeholder="Busque un producto aquí"></input>
                </div>
                <div className="search-engine-result mg-tb-lg pd-rl-xl">
                    <img className="mg-r-md" src={searchImage} alt="search"></img>
                    <span>Se encontraron <b>{this.props.amountProducts}</b> productos.</span>
                </div>
            </div>
        );
    }

    handleClick() {
        this.props.handleSearch(this.buttonReference.current.value);
    }

    handleEnter(e) {
        this.props.handleSearch(e.target.value);
    }
}

export default SearchEngine;